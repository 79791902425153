<template>
    <div :class="classes" class="ml-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full">
        {{ text }}
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        classes() {
            const activePlan = this.data.user_plans.filter(plan => plan.is_active);
            const is_active = activePlan.length > 0;
            return {
                'bg-green-200': is_active,
                'text-green-700 ': is_active
            };
        },
        text() {
            return this.data.user_plans.length > 0 ? 'Elegível' : 'Inelegível';
        }
    }
};
</script>

<style></style>
